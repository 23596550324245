
.btns {
    position: fixed;
    bottom: 2rem;

    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 3rem;
    width: 100%;
    z-index: 50;
}

.btn {
    width: 7rem;
    padding: 1rem .5rem;
    font-size: 1rem;
    
    border: none;
    color: #fff;
       /* other styles */
       background: rgba(39, 39, 39, 0.1);
       backdrop-filter: blur(10px);
        /* fallback for old browsers */
       -webkit-backdrop-filter: blur(60px);
       border: 1px solid rgba(255, 255, 255, 0.2);
    
}

.btn:hover {
    scale: 1.1;
    cursor: pointer;
    border: 2px solid #899651;
    z-index: 5;
}

a {
    text-decoration: none;
    color: white;
    padding-bottom: 0!important;
}

p#resume-word {
    margin: 0!important;
}

 

@media only screen and (max-width: 27rem) {
    .btns {
        bottom: 0;
    
    }
}