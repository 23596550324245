
.projects-section {
    
    display: flex;
    justify-content: center;
    align-items: center;
    
   
    height: 100vh; 
    width: 100%;

   
}


.projects {
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
    

    width: 60%;
    height: 70%;
     /* other styles */
     background: rgba(39, 39, 39, 0.1);
     backdrop-filter: blur(30px);
      /* fallback for old browsers */
     -webkit-backdrop-filter: blur(60px);
     border: 2px solid rgba(255, 255, 255, 0.2);
}


.project {
    width: 25%;
    height: 60%;
    padding: 1rem;


    color: #fff;
    text-align: center;


     /* other styles */
     background: rgba(39, 39, 39, 0.1);
     backdrop-filter: blur(30px);
      /* fallback for old browsers */
     -webkit-backdrop-filter: blur(60px);
     border: 1px solid rgba(255, 255, 255, 0.2);
}



.project-image {
    width: 100%;
    height: 50%;    
}


.project-image img {
    width: 100%;
}

.project-image img:hover {
    scale: 1.1;
    cursor: pointer;
}







@media only screen and (max-width: 27rem) {
    .projects-section {
        height: unset;
        overflow: visible;
    }
    
    
    .projects {
        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 1rem;
        
        height: 100%;
        width: 90%;
        padding-top: 1rem;
        padding-bottom: 6rem;
     
    
    
        /* other styles */
        background: none;
        backdrop-filter: none;
        /* fallback for old browsers */
        -webkit-backdrop-filter: blur(60px);
        border: none;
    }
    
    
    .project {
        width: 75%;
        height: 40%;
        margin: 0;
    
        color: #fff;
        text-align: center;
    
    
         /* other styles */
         background: rgba(39, 39, 39, 0.1);
         backdrop-filter: blur(30px);
          /* fallback for old browsers */
         -webkit-backdrop-filter: blur(60px);
         border: 1px solid rgba(255, 255, 255, 0.2);
    }
    
    .project-image {
        height: 100%;
    }
}